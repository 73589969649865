// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .LoginReact-SnackBar-container{
    z-index: 999999;
}`, "",{"version":3,"sources":["webpack://./src/components/SnackBar/SnackBar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":["body .LoginReact-SnackBar-container{\n    z-index: 999999;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
