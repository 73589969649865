import axios from "axios";
const headers = {
  "Ocp-Apim-Subscription-Key": process.env.REACT_APP_APIMANAGEMENT_HEADER,
  "Content-Type": "application/json",
};
export const getCheckUser = async (entity) => {
  const config = {
    headers: headers,
    method: "post",
    url: process.env.REACT_APP_CHECK_USER,
    data: entity,
  };
  const response = await axios(config);
  return response;
};

export const getUserOnBoarding = async (b2cId, Resource) => {
  try {
    const config = {
      headers,
      method: "get",
      url: process.env.REACT_APP_GET_USER_ON_BOARDING,
      params: {
        UserId: b2cId,
        Resource: Resource,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getYardOwnerByScac = async (ScacCode) => {
  try {
    const config = {
      headers,
      method: "get",
      url: process.env.REACT_APP_GET_YARD_OWNER_BY_SCAC,
      params: {
        ScacCode,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    console.log(error);
  }
};
