import LoginButton from "./components/button/button2";
import NotificationAlert from "./components/SnackBar/SnackBar";
import { SnackBarProvider } from "./context/SnackBarContext";
import { StoreProvider } from "./store/storeContext";
import { MsalProvider } from "@azure/msal-react";
import { CookiesProvider } from "react-cookie";
export default function Root({ msalInstance }) {
  return (
    <CookiesProvider defaultSetOptions={{ path: "/" }}>
      <section>
        <SnackBarProvider>
          <MsalProvider instance={msalInstance}>
            <StoreProvider>
              <LoginButton />
              <NotificationAlert />
            </StoreProvider>
          </MsalProvider>
        </SnackBarProvider>
      </section>
    </CookiesProvider>
  );
}
