/* eslint-disable react-hooks/rules-of-hooks */
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { getUserOnBoarding, getYardOwnerByScac } from "../services/services";
import { useSnackBar } from "../context/SnackBarContext";
import Cookies from "universal-cookie";
const msalPlugin = () => {
  const cookies = new Cookies(null, { path: "/" });
  const { instance } = useMsal();
  const [b2cLoading, setb2cLoading] = React.useState(false);
  const { handleRenderSnackBar } = useSnackBar();
  const [userDataCosmos, setUserDataCosmos] = React.useState({
    name: "",
    email: [""],
  });
  const [lastUrl, setLastUrl] = React.useState("");

  const [creatingUser, setCreatingUser] = useState(false);

  //MSAL FUNCTIONS------------------------------------------------------
  async function signIn() {
    try {
      cookies.remove("sessionCosmos");
      localStorage.clear();
      sessionStorage.clear();
      await instance.loginRedirect({
        scopes: ["openid", "profile"],
      });
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      cookies.remove("sessionCosmos");

      instance.logoutRedirect();
    }
  }

  async function signOut() {
    window.dispatchEvent(new CustomEvent("logOut"));
    setUserDataCosmos({ name: "", email: [""] });
    setb2cLoading(true);
    setTimeout(() => {
      instance.logoutRedirect();
    }, 1000);
  }

  function isAuthenticated() {
    return instance.getActiveAccount() !== null;
  }

  useEffect(() => {
    (async () => {
      try {
        await instance.initialize();
        const isAuth = isAuthenticated();
        if (!instance) {
          console.error("MSAL instance is not initialized.");
          return;
        } else if (isAuth) {
          setActiveOnBoardingUser();
        } else {
          const response = await instance.handleRedirectPromise();
          if (response) {
            setActiveOnBoardingUser();
          }
        }
        window.addEventListener("popstate", (e) => {
          handleValidateIsInCartorYardOwner();
          handleCheckIfUrlisOfLanding();
        });
        handleValidateIsInCartorYardOwner();
        handleCheckIfUrlisOfLanding();
      } catch (e) {
        console.error("Error en el effect al iniciar sesión:", e);
      }
    })();
  }, []);

  //Custom User Admin -----------------------------------------------------------------------

  function setActiveOnBoardingUser() {
    setb2cLoading(true);
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      const YardOwnerInComingExist = handleCheckIfYardOwnerInComingExist();
      if (!YardOwnerInComingExist) {
        handleGetUserDataCosmos(accounts);
      } else {
        handleEvaluateYardOwnerIncoming(accounts);
      }
    }
  }

  function handleEvaluateYardOwnerIncoming(accounts) {
    getUserOnBoarding(accounts[0].idTokenClaims.sub, "YMSAdmin")
      .then((response) => {
        if (response.data) {
          if (response.data.type === "YardOwner") {
            getYardOwnerByScac(response.data.scac_code).then(
              (responseYardOwner) => {
                if (responseYardOwner.data.status !== "Created") {
                  let responseToSave = response.data;
                  responseToSave = {
                    ...responseToSave,
                    YardOwnerStatus: responseYardOwner.data.status,
                  };
                  setUserDataCosmos(responseToSave);
                  saveCookie(responseToSave);
                  eventUserCreated();
                  buildEvents(responseToSave);
                  setb2cLoading(false);
                  setCreatingUser(false);
                  const localStorageYardOwnerCustomIngoming = JSON.parse(
                    localStorage.getItem("YardOwnerCustomInComing")
                  );
                  let urlBuilded = `${window.location.origin}/landing-page/custom-agreement?YardOwnerScacCode=${localStorageYardOwnerCustomIngoming.ScacCode}`;
                  if (
                    localStorageYardOwnerCustomIngoming &&
                    window.location.href !== urlBuilded &&
                    !window.location.href.includes(
                      "/landing-page/custom-agreement?"
                    )
                  ) {
                    window.history.pushState(
                      {},
                      null,
                      `${window.location.origin}/landing-page/custom-agreement?YardOwnerScacCode=${localStorageYardOwnerCustomIngoming.ScacCode}`
                    );
                  }
                  localStorage.removeItem("YardOwnerInComing");
                  if (
                    window.location.href.includes("yms-admin/create-user") ||
                    window.location.href.includes("yms-admin/create-yard-owner")
                  ) {
                    window.location.href = `${window.location.origin}/HomeView`;
                  }
                } else {
                  handleRenderSnackBar(
                    "Your Yard Owner Soliciation is in process, please wait for the approval. Contact to draypower.  ",
                    "error"
                  );
                  setTimeout(() => {
                    signOut();
                  }, 5000);
                }
              }
            );
          } else {
          }
        }
      })
      .catch(() => {
        if (!window.location.href.includes("yms-admin/create-yard-owner")) {
          window.location.href = `${window.location.origin}/yms-admin/create-yard-owner`;
        }
        eventUserCreated();
        setCreatingUser(true);
        setb2cLoading(false);
      });
  }
  //Get User Data Cosmos -----------------------------------------------------------------------

  function handleGetUserDataCosmos(accounts) {
    const mapOfPermission = mapperOfPermission(accounts[0].idTokenClaims.sub);
    getUserOnBoarding(accounts[0].idTokenClaims.sub, mapOfPermission).then(
      (response) => {
        if (response) {
          if (response.data) {
            if (response.data.status === "Approved") {
              let responseToSave = response.data;
              if (response.data.type === "YardOwner") {
                getYardOwnerByScac(response.data.scac_code).then(
                  (responseYardOwner) => {
                    if (
                      responseYardOwner.data.status !== "Enabled" &&
                      !window.location.href.includes("custom-agreement")
                    ) {
                      setb2cLoading(true);
                      handleRenderSnackBar(
                        "User doesn't approved yet, please wait for the approval. Contact to your administrator.  ",
                        "error"
                      );
                      setTimeout(() => {
                        signOut();
                      }, 2000);
                    }
                    responseToSave = {
                      ...responseToSave,
                      YardOwnerStatus: responseYardOwner.data.status,
                    };
                    setUserDataCosmos(responseToSave);
                    saveCookie(responseToSave);
                    eventUserCreated();
                    buildEvents(responseToSave);
                    setb2cLoading(false);
                    setCreatingUser(false);
                    if (
                      window.location.href.includes("yms-admin/create-user") ||
                      window.location.href.includes(
                        "yms-admin/create-yard-owner"
                      )
                    ) {
                      window.location.href = `${window.location.origin}/landing-page/home`;
                    }
                    if (
                      (window.location.href.includes("yms-portal") &&
                        response.data.type === "Carrier") ||
                      (window.location.href.includes("yms-chassis-portal") &&
                        response.data.type === "Carrier") ||
                      (window.location.href.includes("yms-admin") &&
                        response.data.type === "Carrier")
                    ) {
                      window.history.pushState({}, null, "/HomeView");
                    }
                  }
                );
              } else {
                setUserDataCosmos(responseToSave);
                saveCookie(responseToSave);
                eventUserCreated();
                buildEvents(responseToSave);
                setb2cLoading(false);
                setCreatingUser(false);
                if (
                  window.location.href.includes("yms-admin/create-user") ||
                  window.location.href.includes("yms-admin/create-yard-owner")
                ) {
                  window.location.href = `${window.location.origin}/landing-page/home`;
                }
                if (
                  (window.location.href.includes("yms-portal") &&
                    response.data.type === "Carrier") ||
                  (window.location.href.includes("yms-chassis-portal") &&
                    response.data.type === "Carrier") ||
                  (window.location.href.includes("yms-admin") &&
                    response.data.type === "Carrier")
                ) {
                  window.history.pushState({}, null, "/HomeView");
                }
              }
            } else {
              handleRenderSnackBar(
                "User doesn't approved yet, please wait for the approval. Contact to your administrator.  ",
                "error"
              );
              setTimeout(() => {
                signOut();
              }, 5000);
            }
          }
        } else {
          if (!window.location.href.includes("yms-admin/create-user")) {
            window.location.href = `${window.location.origin}/yms-admin/create-user`;
          }
          eventUserCreated();
          setb2cLoading(false);
          setCreatingUser(true);
        }
      }
    );
    return;
  }

  // Custom Events -----------------------------------------------------------------------
  function eventUserCreated() {
    const account = instance.getAllAccounts();

    const event = new CustomEvent("userInB2C", {
      detail: account[0],
    });
    window.dispatchEvent(event);
  }

  // Save Cookie -----------------------------------------------------------------------
  function saveCookie(response) {
    cookies.set("sessionCosmos", response, {
      secure: true,
      sameSite: "strict",
      path: "/",
    });
  }

  // Get Cookie -----------------------------------------------------------------------
  function handleGetSessionCosmos() {
    const isAuth = isAuthenticated();
    if (isAuth) {
      return cookies.get("sessionCosmos");
    } else {
      cookies.remove("sessionCosmos");
      return null;
    }
  }

  async function handleValidateIsInCartorYardOwner() {
    const isAuth = isAuthenticated();
    const sessionCosmos = handleGetSessionCosmos();
    if (
      (window.location.href.includes("cart-products") &&
        !sessionCosmos &&
        !isAuth) ||
      (window.location.href.includes("custom-agreement") &&
        !sessionCosmos &&
        !isAuth) ||
      (window.location.href.includes("yms-admin/create-yard-owner") &&
        !sessionCosmos &&
        !isAuth)
    ) {
      await signIn();
    }
  }

  // Build Events -----------------------------------------------------------------------
  function buildEvents(response) {
    function eventUserDataLoaded() {
      const event = new CustomEvent("UserDataLoaded", {
        detail: response,
      });
      window.dispatchEvent(event);
    }

    eventUserDataLoaded();
    window.addEventListener("popstate", async () => {
      const isAuth = isAuthenticated();

      if (!(isAuth && lastUrl !== window.location.href)) {
        setLastUrl(window.location.href);
        return;
      }
      eventUserDataLoaded();
      setLastUrl(window.location.href);
    });
  }

  // Check if URL is of Landing -----------------------------------------------------------------------

  function handleCheckIfUrlisOfLanding() {
    const isAuth = isAuthenticated();
    const sessionCosmos = handleGetSessionCosmos();
    if (
      !window.location.href.includes("landing") &&
      !window.location.href.includes("yms-admin/create-yard-owner") &&
      !sessionCosmos &&
      !isAuth
    ) {
      window.history.pushState({}, null, "/landing-page/home");
    }
  }

  // Mapper of Permission depending of user -----------------------------------------------------------------------

  function mapperOfPermission() {
    const url = window.location.href;
    const baseUrl = window.location.origin + window.location.pathname;
    if (url.includes("landing-page")) {
      return "Products";
    } else if (
      url.includes("user-management") ||
      url.includes("yms-admin") ||
      url.includes("HomeView") ||
      url === baseUrl
    ) {
      return "UserManagement";
    }
  }

  function handleAskUserFromPersonalizedPermission(permission) {
    const account = instance.getAllAccounts();
    getUserOnBoarding(account[0].idTokenClaims.sub, permission).then(
      (response) => {
        if (response.data) {
          window.dispatchEvent(
            new CustomEvent("responseOfPermissionAsked", {
              detail: response.data,
            })
          );
        }
      }
    );
  }

  // Check if Yard Owner In Coming Exist -----------------------------------------------------------------------

  function handleCheckIfYardOwnerInComingExist() {
    return localStorage.getItem("YardOwnerInComing") ||
      localStorage.getItem("YardOwnerCustomInComing")
      ? true
      : false;
  }

  //React Use Effect to Register Events -----------------------------------------------------------------------

  React.useEffect(() => {
    window.addEventListener("addingProduct", () => {
      instance.loginRedirect();
    });
    window.addEventListener("sessionCosmosNotExists", () => {
      instance.loginRedirect();
    });
    window.addEventListener("popstate", () => {
      eventUserCreated();
    });
    window.addEventListener("getUserData", () => {
      eventUserCreated();
    });
    window.addEventListener("AskUserFromPersonalizedPermission", (e) => {
      handleAskUserFromPersonalizedPermission(e.detail.permission);
    });
    window.addEventListener("logOutRequired", (e) => {
      signOut();
    });
  }, []);

  return {
    cookies,
    userDataCosmos,
    b2cLoading,
    signIn,
    signOut,
    isAuthenticated,

    creatingUser,
  };
};

export default msalPlugin;
