import React from "react";
import { Alert, Snackbar } from "@mui/material";
import { useSnackBar } from "../../context/SnackBarContext";
import "./SnackBar.css";
const NotificationAlert = () => {
  const { SnackBarInformation, handleCloseSnackBar } = useSnackBar();

  return (
    <Snackbar
      open={SnackBarInformation.SnackBarState}
      autoHideDuration={6000}
      onClose={() => handleCloseSnackBar()}
      className="LoginReact-SnackBar-container"
    >
      <Alert
        variant="filled"
        autoHideDuration={6000}
        onClose={() => handleCloseSnackBar()}
        severity={SnackBarInformation.SnackBarType}
      >
        {SnackBarInformation.SnackBarMessage}
      </Alert>
    </Snackbar>
  );
};

export default NotificationAlert;
