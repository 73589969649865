import * as React from "react";
export const StoreContext = React.createContext();
export const useStore = () => React.useContext(StoreContext);
import msalPlugin from "../msal/msalPlugin";

export const StoreProvider = ({ children }) => {
  const {
    cookies,
    signIn,
    b2cLoading,
    creatingUser,
    userDataCosmos,
    lastUrl,
    signOut,
  } = msalPlugin();
  return (
    <StoreContext.Provider
      value={{
        cookies,
        signIn,
        signOut,
        b2cLoading,
        creatingUser,
        userDataCosmos,
        lastUrl,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
